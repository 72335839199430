
// Misc

$buttonHeight: 78px;
$buttonHeightMobile: 64px;


// Colours

$colourCoral: #cd545b;
$colourBlue: #0076a8;
$colourGrey: #888b8d;
$colourYellow: #fed141;
$colourPurple: #686e9f;
$colourLightBlue: #62b5e5;
$colourOrange: #f58545; //#ff7f41;
$colourGreen: #74aa50;

$colourWhite: #ffffff;
$colourBlack: #000000;
$colourAccent: #37cda8; // Teal
$colourPrimary: #0a0909;
$colourBackground: #ffffff;

$colourVeryDarkGrey: #141414;
$colourDarkGrey: #3a3a3a;
$colourMediumGrey: #898989;
$colourLightGrey: #b2b2b2;
$colourVeryLightGrey: #ebebeb;
$colourAlmostWhite: #fafafa;

$colourLinks: $colourBlue;
$colourSuccess: #1f9812;
$colourError: #bf0625;

$colours: (
							'white': $colourWhite, 
							'accent': $colourAccent, 
							'coral': $colourCoral, 
							'blue': $colourBlue, 
							'primary': $colourPrimary, 
							'grey': $colourGrey,
							'yellow': $colourYellow,
							'purple': $colourPurple,
							'light-blue': $colourLightBlue,
							'orange': $colourOrange,
							'green': $colourGreen,
							'black': $colourBlack,
							);

// Fonts;

$fontFamilyPrimary: 'Montserrat', 'Helvetica Neue', 'Arial', Helvetica, Arial, sans-serif;
$fontFamilyDisplay: 'peace_sansregular', 'Arial', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Font Sizes;

$fontSizeRoot: 17px;
$fontSizeRootLarge: 20px;
$fontSizeRootExtraLarge: 24px;

$fontSizeRootMedium: 15px;
$fontSizeRootMobile: 13px;

$fontSizeNav: 1.2rem;

$fontSizeTiny: 0.65rem;
$fontSizeSmall: 0.85rem;
$fontSizeNormal: 1rem;
$fontSizeMedium: 1.2rem;
$fontSizeLarge: 2.0rem;
$fontSizeExtraLarge: 3.9rem;
$fontSizeHuge: 8.4rem;


// Font Weights;

$fontWeightExtraBold: 800;
$fontWeightBold: 700;
$fontWeightMedium: 600;
$fontWeightRegular: 400;
$fontWeightLight: 300;

// Z Index;
$zIndexTop: 999;
$zIndexHigh: 900;
$zIndexMedium: 500;
$zIndexLow: 200;
$zIndexBottom: -1;