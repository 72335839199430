

/* Debug
---------------------------------------------------------------------------------------------------- */

body {
/*
    &:after {
    content: 'default / xs';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    line-height: 10px;
    text-align: center;
    font-size: 10px;
    color: $colourWhite;
    z-index: $zIndexTop;
    background-color: pink;
    @include breakpoint(xs) {
      content: 'xs';
      background-color: red;
    }
    @include breakpoint(sm) {
      content: 'sm';
      background-color: green;
    }
    @include breakpoint(md) {
      content: 'md';
      background-color: blue;
    }
    @include breakpoint(lg) {
      content: 'lg';
      background-color: yellow;
    }
    @include breakpoint(xlg) {
      content: 'xlg';
      background-color: purple;
    }
  }
*/
  

}




/* Fonts
---------------------------------------------------------------------------------------------------- */

@font-face {
    font-family: 'peace_sansregular';
    src: url('../fonts/peace_sans_webfont-webfont.woff2') format('woff2'),
         url('../fonts/peace_sans_webfont-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}






/* Shared Containers
---------------------------------------------------------------------------------------------------- */

html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: $fontFamilyPrimary;
	background: $colourAccent;
	color: $colourWhite;
	font-size: $fontSizeRootMobile;
	@include breakpoint(sm) {
		font-size: $fontSizeRootMedium;
	}
	@include breakpoint(lg) {
		font-size: $fontSizeRoot;
	}
	
  a {
	  &:hover, &:active {

	  }
	  &:focus {
		  outline: none;
	  }
  }
  
}

[data-permalink]:hover {
  cursor: pointer;
  opacity: 0.8;
}




/* Landing PAge
---------------------------------------------------------------------------------------------------- */

#main {
	height: 100%;
	.outer {
		height: 100%;
		width: 100%;
		display: table;
		.inner {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
		}
	}
	.content-wrapper {
		text-align: center;
		padding: 20px 0;
		margin: 0;
		width: 100%;
		.logo {
			display: block;
			margin: 0 0 20px 0;
			padding: 0;
			height: 80px;
			@include breakpoint(sm) {
				height: 120px;
				margin: 0 0 40px 0;
			}
			@include breakpoint(md) {

			}
			@include breakpoint(lg) {
				height: 120px;
			}
			a {
				display: block;
				background: url(../img/logo-uniting-sa.png) no-repeat center center;
				background-size: auto 100%;
				width: 100%;
				height: 100%;
				&:hover, &:active {
					opacity: 0.7;
				}
			}
		}
		.content {
			width: 80%;
			margin: 0 10%;
			position: relative;
			padding: 30px 20px;	
			@include breakpoint(sm) {
				width: 600px;
				padding: 30px 10px;	
				margin: 0 auto;
			}
			@include breakpoint(md) {
				width: 600px;
			}
			@include breakpoint(lg) {
				width: 600px;
			}
			&:before, 
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 20px;
				background: url(../img/divider-reversed.svg) no-repeat center center;
				background-size: 100% auto;
			}
			&:after {
				top: auto; 
				bottom: 0;
			}
			p {
				color: #000000;
				font-size: 16px;
				line-height: 1.4em;
				@include breakpoint(sm) {
					font-size: 30px;
					line-height: 1.3em;
				}
			}
			strong {
				text-transform: uppercase;
				font-family: $fontFamilyDisplay;
				letter-spacing: 1px;
				font-size: 20px;
				line-height: 1.3em;
				@include breakpoint(sm) {
					font-size: 38px;
				}
				a {
					color: $colourWhite;
					display: block;
					margin: 10px 0 0 0;
					&:hover, &:active {
						text-decoration: none;
						opacity: 0.7;
					}
				}
			}
		}
	}
}




