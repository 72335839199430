// Mixins

@mixin retinaBackgroundImage($file, $type, $width, $height, $hideContent) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background-image: url($file + '@2x.' + $type);
    -webkit-background-size: $width $height;
    -moz-background-size: $width $height;
    -o-background-size: $width $height;
    background-size: $width $height;
    @if $hideContent == true {
      text-indent: -9999px;
      display: block;
      overflow: hidden; } } }


@mixin retina() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    @content; } }

@mixin breakpoint($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content; } }

@mixin floatUp() {
  -webkit-transition: translate(0, -10px);
  -moz-transition: translate(0, -10px);
  -ms-transition: translate(0, -10px);
  -o-transition: translate(0, -10px);
  transition: translate(0, -10px); }


@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) {
      @content; } }
  @else if $class == xxs {
    @media (max-width: 320px) {
      @content; } }
  @else if $class == sm {
    @media (min-width: 768px) {
      @content; } }
  @else if $class == md {
    @media (min-width: 992px) {
      @content; } }
  @else if $class == lg {
    @media (min-width: 1200px) {
      @content; } }
  @else if $class == xlg {
    @media (min-width: 1440px) {
      @content; } }
  @else {
    @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg, xlg"; } }


@mixin vertical-breakpoint($class) {
  @if $class == xs {
    @media (max-height: 319px) {
      @content; } }
  @else if $class == sm {
    @media (max-height: 400px) {
      @content; } }
  @else if $class == md {
    @media (max-height: 739px) {
      @content; } }
  @else if $class == lg {
    @media (min-height: 740px) {
      @content; } }
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg"; } }


@mixin breakpointSmallerThan($class) {
  @if $class == xs {
    @media (max-width: 767px) {
      @content; } }
  @else if $class == xxs {
    @media (max-width: 320px) {
      @content; } }
  @else if $class == sm {
    @media (max-width: 768px) {
      @content; } }
  @else if $class == md {
    @media (max-width: 992px) {
      @content; } }
  @else if $class == lg {
    @media (max-width: 1200px) {
      @content; } }
  @else if $class == xlg {
    @media (max-width: 1440px) {
      @content; } }
  @else {
    @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg, xlg"; } }


@mixin breakpointBiggerThan($size) {
  @media (min-width: $size) {
    @content; } }



@mixin fadeIn() {
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn; }

@mixin fadeInUp() {
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@mixin noAnimations() {
  animation-duration: 0s;
  -webkit-animation-duration: 0s;
  -moz-animation-duration: 0s;
  -ms-animation-duration: 0s; }

