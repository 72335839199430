@include keyframes(spin) {
  0% {
    @include transform(rotate(-0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}

@include keyframes(moveInRight) {
  0%   { @include transform(translateX(100%)); }
  100% { @include transform(translateX(0%)); }
}

@include keyframes(moveOutRight) {
  0%   { @include transform(translateX(0%)); }
  100% { @include transform(translateX(100%)); }
}

@include keyframes(fadeInUp) {
  0%   { @include transform(translateY(40px)); }
  100% { @include transform(translateY(0%)); opacity: 1; }
}

@include keyframes(fadeInUpSmall) {
  0%   { @include transform(translateY(15px)); }
  100% { @include transform(translateY(0%)); opacity: 1; }
}

@include keyframes(fadeInDown) {
  0%   { @include transform(translateY(-40px) translateX(0)); }
  100% { @include transform(translateY(0px) translateX(0)); opacity: 1; }
}

@include keyframes(fadeOutDown) {
  0%   { @include transform(translateY(0px)); }
  100% { @include transform(translateY(40px)); opacity: 0; }
}

@include keyframes(fadeInRight) {
  0%   { @include transform(translateX(-25px)); }
  100% { @include transform(translateX(0px)); opacity: 1; }
}

@include keyframes(fadeInUp) {
  0%   { @include transform(translateY(40px)); }
  100% { @include transform(translateY(0px)); opacity: 1; }
}

@include keyframes(fadeIn) {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@include keyframes(fadeAndScaleIn) {
  0%   { opacity: 0; @include transform(scale(0.7)) }
  100% { opacity: 1; @include transform(scale(1)) }
}

@include keyframes(fadeAndScaleOut) {
  0%   { opacity: 1; @include transform(scale(1)) }
  100% { opacity: 0; @include transform(scale(0.7)) }
}

@include keyframes(fadeAndScaleInSmall) {
  0%   { opacity: 0; @include transform(scale(0.9)) }
  100% { opacity: 1; @include transform(scale(1)) }
}

@include keyframes(fadeOut) {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

@include keyframes(zoomIn) {
  0%   { @include transform(scale(0.7)); }
  100% { @include transform(scale(1)); opacity: 1; }
}

@include keyframes(zoomOut) {
  0%   { @include transform(scale(1)); }
  100% { @include transform(scale(0)); opacity: 0; }
}

@include keyframes(scaleUpOut) {
  0%   { @include transform(scale(1)); }
  100% { @include transform(scale(1.4)); opacity: 0; }
}

@include keyframes(rubberBand) {
  0% {transform: scaleX(1.0) scaleY(1.25);}
  30% {transform: scaleX(1.15) scaleY(0.85);}
  100% {transform: scale(1);}
}

@include keyframes(pointDown) {
  0% { @include transform(translateY(0%)); }
  50% { @include transform(translateY(10%)); }
  100% { @include transform(translateY(0%)); }
}

@include keyframes(scaleUpAndDown) {
  0%   { @include transform(scale(1)); }
  50%  { @include transform(scale(1.05)); }
  100% { @include transform(scale(1)); }
}

@include keyframes(zoomAndTiltBackAndForth) {
  0%   { @include transform(scale(1) rotate(0)); }
  50%  { @include transform(scale(1.1) rotate(-3deg)); }
  100% { @include transform(scale(1) rotate(0)); }
}

@include keyframes(pointRight) {
  0% { @include transform(translateX(0%)); }
  50% { @include transform(translateX(10%)); }
  100% { @include transform(translateX(0%)); }
}

@include keyframes(panUpAndDown) {
  0%   { @include transform(translateX(0) translateY(5%) scale(1.05)); }
  50%  { @include transform(translateX(0) translateY(0%) scale(1.0)); }
  100% { @include transform(translateX(0) translateY(5%) scale(1.05)); }
}

@include keyframes(panUpAndDownMobile) {
  0%   { @include transform(translateX(0) translateY(0)); }
  50%  { @include transform(translateX(0) translateY(-15px)); }
  100% { @include transform(translateX(0) translateY(0)); }
}

@include keyframes(panBackgroundRight) {
  0% { background-position: 0 0; }
  100% { background-position: -200px 0; }
}

@include keyframes(scaleBackgroundInAndOut) {
  0% { background-size: auto 85%; }
  50% { background-size: auto 95%; }
  100% { background-size: auto 85%; }
}

@include keyframes(shake) {
  from, to {
    @include transform(translateX(0));
  }

  10%, 30%, 50%, 70%, 90% {
    @include transform(translateX(-10px));
  }

  20%, 40%, 60%, 80% {
    @include transform(translateX(10px));
  }
}

@-moz-keyframes bounce {
  0% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  1.67% {-moz-transform:translate(0px, -1.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-moz-transform:translate(0px, -3.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  5% {-moz-transform:translate(0px, -5.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-moz-transform:translate(0px, -7.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  8.33% {-moz-transform:translate(0px, -9.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-moz-transform:translate(0px, -10.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  11.67% {-moz-transform:translate(0px, -12.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-moz-transform:translate(0px, -13.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  15% {-moz-transform:translate(0px, -15.3px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-moz-transform:translate(0px, -16.6667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  18.33% {-moz-transform:translate(0px, -17.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-moz-transform:translate(0px, -19.2px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  21.67% {-moz-transform:translate(0px, -20.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-moz-transform:translate(0px, -21.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  25% {-moz-transform:translate(0px, -22.5px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-moz-transform:translate(0px, -23.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.33% {-moz-transform:translate(0px, -24.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-moz-transform:translate(0px, -25.2px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  31.67% {-moz-transform:translate(0px, -25.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-moz-transform:translate(0px, -26.6667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  35% {-moz-transform:translate(0px, -27.3px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-moz-transform:translate(0px, -27.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.33% {-moz-transform:translate(0px, -28.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-moz-transform:translate(0px, -28.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  41.67% {-moz-transform:translate(0px, -29.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-moz-transform:translate(0px, -29.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  45% {-moz-transform:translate(0px, -29.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-moz-transform:translate(0px, -29.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  48.33% {-moz-transform:translate(0px, -29.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-moz-transform:translate(0px, -30px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  51.67% {-moz-transform:translate(0px, -29.7479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-moz-transform:translate(0px, -28.9917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  55% {-moz-transform:translate(0px, -27.7312px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-moz-transform:translate(0px, -25.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  58.33% {-moz-transform:translate(0px, -23.6979px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-moz-transform:translate(0px, -20.925px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.67% {-moz-transform:translate(0px, -17.6479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-moz-transform:translate(0px, -13.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  65% {-moz-transform:translate(0px, -9.5813px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-moz-transform:translate(0px, -4.7917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  68.33% {-moz-transform:translate(0px, -0.2479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-moz-transform:translate(0px, -2.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.67% {-moz-transform:translate(0px, -4.6479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-moz-transform:translate(0px, -6.0917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  75% {-moz-transform:translate(0px, -7.0313px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-moz-transform:translate(0px, -7.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  78.33% {-moz-transform:translate(0px, -7.3979px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-moz-transform:translate(0px, -6.825px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  81.67% {-moz-transform:translate(0px, -5.7479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-moz-transform:translate(0px, -4.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85% {-moz-transform:translate(0px, -2.0812px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-moz-transform:translate(0px, -0.2417px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  88.33% {-moz-transform:translate(0px, -1.2729px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-moz-transform:translate(0px, -1.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  91.67% {-moz-transform:translate(0px, -1.8229px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-moz-transform:translate(0px, -1.3417px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95% {-moz-transform:translate(0px, -0.3563px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-moz-transform:translate(0px, -0.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  98.33% {-moz-transform:translate(0px, -0.4354px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-webkit-keyframes bounce {
  0% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  1.67% {-webkit-transform:translate(0px, -1.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-webkit-transform:translate(0px, -3.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  5% {-webkit-transform:translate(0px, -5.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-webkit-transform:translate(0px, -7.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  8.33% {-webkit-transform:translate(0px, -9.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-webkit-transform:translate(0px, -10.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  11.67% {-webkit-transform:translate(0px, -12.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-webkit-transform:translate(0px, -13.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  15% {-webkit-transform:translate(0px, -15.3px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-webkit-transform:translate(0px, -16.6667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  18.33% {-webkit-transform:translate(0px, -17.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-webkit-transform:translate(0px, -19.2px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  21.67% {-webkit-transform:translate(0px, -20.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-webkit-transform:translate(0px, -21.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  25% {-webkit-transform:translate(0px, -22.5px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-webkit-transform:translate(0px, -23.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.33% {-webkit-transform:translate(0px, -24.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-webkit-transform:translate(0px, -25.2px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  31.67% {-webkit-transform:translate(0px, -25.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-webkit-transform:translate(0px, -26.6667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  35% {-webkit-transform:translate(0px, -27.3px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-webkit-transform:translate(0px, -27.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.33% {-webkit-transform:translate(0px, -28.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-webkit-transform:translate(0px, -28.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  41.67% {-webkit-transform:translate(0px, -29.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-webkit-transform:translate(0px, -29.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  45% {-webkit-transform:translate(0px, -29.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-webkit-transform:translate(0px, -29.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  48.33% {-webkit-transform:translate(0px, -29.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-webkit-transform:translate(0px, -30px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  51.67% {-webkit-transform:translate(0px, -29.7479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-webkit-transform:translate(0px, -28.9917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  55% {-webkit-transform:translate(0px, -27.7312px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-webkit-transform:translate(0px, -25.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  58.33% {-webkit-transform:translate(0px, -23.6979px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-webkit-transform:translate(0px, -20.925px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.67% {-webkit-transform:translate(0px, -17.6479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-webkit-transform:translate(0px, -13.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  65% {-webkit-transform:translate(0px, -9.5813px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-webkit-transform:translate(0px, -4.7917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  68.33% {-webkit-transform:translate(0px, -0.2479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-webkit-transform:translate(0px, -2.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.67% {-webkit-transform:translate(0px, -4.6479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-webkit-transform:translate(0px, -6.0917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  75% {-webkit-transform:translate(0px, -7.0313px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-webkit-transform:translate(0px, -7.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  78.33% {-webkit-transform:translate(0px, -7.3979px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-webkit-transform:translate(0px, -6.825px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  81.67% {-webkit-transform:translate(0px, -5.7479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-webkit-transform:translate(0px, -4.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85% {-webkit-transform:translate(0px, -2.0812px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-webkit-transform:translate(0px, -0.2417px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  88.33% {-webkit-transform:translate(0px, -1.2729px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-webkit-transform:translate(0px, -1.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  91.67% {-webkit-transform:translate(0px, -1.8229px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-webkit-transform:translate(0px, -1.3417px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95% {-webkit-transform:translate(0px, -0.3563px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-webkit-transform:translate(0px, -0.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  98.33% {-webkit-transform:translate(0px, -0.4354px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@keyframes bounce {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  1.67% {transform:translate(0px, -1.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {transform:translate(0px, -3.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  5% {transform:translate(0px, -5.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {transform:translate(0px, -7.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  8.33% {transform:translate(0px, -9.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {transform:translate(0px, -10.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  11.67% {transform:translate(0px, -12.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {transform:translate(0px, -13.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  15% {transform:translate(0px, -15.3px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {transform:translate(0px, -16.6667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  18.33% {transform:translate(0px, -17.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {transform:translate(0px, -19.2px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  21.67% {transform:translate(0px, -20.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {transform:translate(0px, -21.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  25% {transform:translate(0px, -22.5px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {transform:translate(0px, -23.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.33% {transform:translate(0px, -24.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {transform:translate(0px, -25.2px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  31.67% {transform:translate(0px, -25.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {transform:translate(0px, -26.6667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  35% {transform:translate(0px, -27.3px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {transform:translate(0px, -27.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.33% {transform:translate(0px, -28.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {transform:translate(0px, -28.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  41.67% {transform:translate(0px, -29.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {transform:translate(0px, -29.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  45% {transform:translate(0px, -29.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {transform:translate(0px, -29.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  48.33% {transform:translate(0px, -29.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {transform:translate(0px, -30px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  51.67% {transform:translate(0px, -29.7479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {transform:translate(0px, -28.9917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  55% {transform:translate(0px, -27.7312px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {transform:translate(0px, -25.9667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  58.33% {transform:translate(0px, -23.6979px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {transform:translate(0px, -20.925px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.67% {transform:translate(0px, -17.6479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {transform:translate(0px, -13.8667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  65% {transform:translate(0px, -9.5813px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {transform:translate(0px, -4.7917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  68.33% {transform:translate(0px, -0.2479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {transform:translate(0px, -2.7px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.67% {transform:translate(0px, -4.6479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {transform:translate(0px, -6.0917px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  75% {transform:translate(0px, -7.0313px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {transform:translate(0px, -7.4667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  78.33% {transform:translate(0px, -7.3979px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {transform:translate(0px, -6.825px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  81.67% {transform:translate(0px, -5.7479px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {transform:translate(0px, -4.1667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85% {transform:translate(0px, -2.0812px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {transform:translate(0px, -0.2417px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  88.33% {transform:translate(0px, -1.2729px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {transform:translate(0px, -1.8px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  91.67% {transform:translate(0px, -1.8229px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {transform:translate(0px, -1.3417px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95% {transform:translate(0px, -0.3563px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {transform:translate(0px, -0.3667px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  98.33% {transform:translate(0px, -0.4354px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}








.animation-target {
  -webkit-animation: animation 1000ms linear both;
  animation: animation 1000ms linear both;
}

/* Generated with Bounce.js. Edit at http://goo.gl/3Eysna */

@-webkit-keyframes popIn { 
  0% { -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1; } 
}

@keyframes popIn { 
  0% { -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1; } 
}





@-webkit-keyframes popOut { 
  0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
  4.7% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}

@keyframes popOut { 
  0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
  4.7% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}






@-moz-keyframes scaleIn {
  0% {-moz-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-moz-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-moz-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-moz-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-moz-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-moz-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-moz-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-moz-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-moz-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-moz-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-moz-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-moz-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-moz-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-moz-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-moz-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-moz-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-moz-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-moz-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-moz-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-moz-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-moz-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-moz-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-moz-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-moz-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-moz-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-moz-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-moz-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-moz-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-moz-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-moz-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-ms-keyframes scaleIn {
  0% {-ms-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-ms-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-ms-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-ms-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-ms-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-ms-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-ms-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-ms-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-ms-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-ms-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-ms-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-ms-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-ms-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-ms-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-ms-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-ms-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-ms-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-ms-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-ms-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-ms-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-ms-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-ms-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-ms-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-ms-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-ms-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-ms-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-ms-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-ms-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-ms-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-ms-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-o-keyframes scaleIn {
  0% {-o-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-o-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-o-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-o-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-o-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-o-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-o-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-o-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-o-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-o-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-o-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-o-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-o-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-o-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-o-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-o-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-o-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-o-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-o-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-o-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-o-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-o-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-o-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-o-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-o-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-o-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-o-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-o-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-o-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-o-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-webkit-keyframes scaleIn {
  0% {-webkit-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-webkit-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-webkit-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-webkit-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-webkit-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-webkit-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-webkit-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-webkit-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-webkit-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-webkit-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-webkit-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-webkit-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-webkit-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-webkit-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-webkit-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-webkit-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-webkit-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-webkit-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-webkit-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-webkit-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-webkit-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-webkit-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-webkit-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-webkit-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-webkit-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-webkit-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-webkit-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-webkit-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-webkit-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-webkit-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@keyframes scaleIn {
  0% {transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}html::after { content: url(https://ga-beacon.appspot.com/UA-42910121-1/stylie?pixel); position: absolute; left: -999em; }









@-moz-keyframes scaleOut {
  0% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-moz-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-moz-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-moz-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-moz-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-moz-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-moz-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-moz-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-moz-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-moz-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-moz-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-moz-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-moz-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-moz-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-moz-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-moz-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-moz-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-moz-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-moz-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-moz-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-moz-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-moz-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-moz-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-moz-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-moz-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-moz-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-moz-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-moz-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-moz-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-moz-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-moz-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-ms-keyframes scaleOut {
  0% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-ms-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-ms-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-ms-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-ms-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-ms-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-ms-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-ms-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-ms-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-ms-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-ms-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-ms-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-ms-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-ms-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-ms-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-ms-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-ms-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-ms-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-ms-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-ms-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-ms-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-ms-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-ms-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-ms-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-ms-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-ms-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-ms-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-ms-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-ms-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-ms-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-ms-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-o-keyframes scaleOut {
  0% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-o-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-o-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-o-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-o-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-o-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-o-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-o-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-o-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-o-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-o-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-o-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-o-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-o-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-o-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-o-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-o-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-o-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-o-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-o-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-o-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-o-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-o-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-o-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-o-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-o-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-o-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-o-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-o-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-o-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-o-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-webkit-keyframes scaleOut {
  0% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-webkit-transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-webkit-transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-webkit-transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-webkit-transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-webkit-transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-webkit-transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-webkit-transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-webkit-transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-webkit-transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-webkit-transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-webkit-transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-webkit-transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-webkit-transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-webkit-transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-webkit-transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-webkit-transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-webkit-transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-webkit-transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-webkit-transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-webkit-transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-webkit-transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-webkit-transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-webkit-transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-webkit-transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-webkit-transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-webkit-transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-webkit-transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-webkit-transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-webkit-transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-webkit-transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@keyframes scaleOut {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {transform:translate(0px, 0px) scale(1.0258) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {transform:translate(0px, 0px) scale(1.0498) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {transform:translate(0px, 0px) scale(1.072) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {transform:translate(0px, 0px) scale(1.0924) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {transform:translate(0px, 0px) scale(1.1111) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {transform:translate(0px, 0px) scale(1.128) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {transform:translate(0px, 0px) scale(1.1431) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {transform:translate(0px, 0px) scale(1.1564) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {transform:translate(0px, 0px) scale(1.168) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {transform:translate(0px, 0px) scale(1.1778) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {transform:translate(0px, 0px) scale(1.1858) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {transform:translate(0px, 0px) scale(1.192) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {transform:translate(0px, 0px) scale(1.1964) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {transform:translate(0px, 0px) scale(1.1991) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {transform:translate(0px, 0px) scale(1.2) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {transform:translate(0px, 0px) scale(1.1947) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {transform:translate(0px, 0px) scale(1.1787) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {transform:translate(0px, 0px) scale(1.152) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {transform:translate(0px, 0px) scale(1.1147) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {transform:translate(0px, 0px) scale(1.0667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {transform:translate(0px, 0px) scale(1.008) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {transform:translate(0px, 0px) scale(0.9387) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {transform:translate(0px, 0px) scale(0.8587) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {transform:translate(0px, 0px) scale(0.768) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {transform:translate(0px, 0px) scale(0.6667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {transform:translate(0px, 0px) scale(0.5547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {transform:translate(0px, 0px) scale(0.432) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {transform:translate(0px, 0px) scale(0.2987) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {transform:translate(0px, 0px) scale(0.1547) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0px) scale(0) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}




@-moz-keyframes poke {
  0% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-moz-transform:translate(0px, 0px) scale(1.063) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-moz-transform:translate(0px, 0px) scale(1.1185) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-moz-transform:translate(0px, 0px) scale(1.1667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-moz-transform:translate(0px, 0px) scale(1.2074) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-moz-transform:translate(0px, 0px) scale(1.2407) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-moz-transform:translate(0px, 0px) scale(1.2667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-moz-transform:translate(0px, 0px) scale(1.2852) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-moz-transform:translate(0px, 0px) scale(1.2963) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-moz-transform:translate(0px, 0px) scale(0.8617) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-moz-transform:translate(0px, 0px) scale(0.8983) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-moz-transform:translate(0px, 0px) scale(0.9733) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-moz-transform:translate(0px, 0px) scale(1.0157) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-moz-transform:translate(0px, 0px) scale(1.0209) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-moz-transform:translate(0px, 0px) scale(1.0099) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-moz-transform:translate(0px, 0px) scale(0.9966) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-moz-transform:translate(0px, 0px) scale(0.9975) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-moz-transform:translate(0px, 0px) scale(0.9993) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-moz-transform:translate(0px, 0px) scale(1.0004) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-moz-transform:translate(0px, 0px) scale(1.0005) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-moz-transform:translate(0px, 0px) scale(1.0002) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-moz-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-moz-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-ms-keyframes poke {
  0% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-ms-transform:translate(0px, 0px) scale(1.063) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-ms-transform:translate(0px, 0px) scale(1.1185) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-ms-transform:translate(0px, 0px) scale(1.1667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-ms-transform:translate(0px, 0px) scale(1.2074) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-ms-transform:translate(0px, 0px) scale(1.2407) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-ms-transform:translate(0px, 0px) scale(1.2667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-ms-transform:translate(0px, 0px) scale(1.2852) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-ms-transform:translate(0px, 0px) scale(1.2963) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-ms-transform:translate(0px, 0px) scale(0.8617) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-ms-transform:translate(0px, 0px) scale(0.8983) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-ms-transform:translate(0px, 0px) scale(0.9733) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-ms-transform:translate(0px, 0px) scale(1.0157) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-ms-transform:translate(0px, 0px) scale(1.0209) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-ms-transform:translate(0px, 0px) scale(1.0099) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-ms-transform:translate(0px, 0px) scale(0.9966) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-ms-transform:translate(0px, 0px) scale(0.9975) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-ms-transform:translate(0px, 0px) scale(0.9993) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-ms-transform:translate(0px, 0px) scale(1.0004) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-ms-transform:translate(0px, 0px) scale(1.0005) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-ms-transform:translate(0px, 0px) scale(1.0002) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-ms-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-ms-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-o-keyframes poke {
  0% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-o-transform:translate(0px, 0px) scale(1.063) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-o-transform:translate(0px, 0px) scale(1.1185) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-o-transform:translate(0px, 0px) scale(1.1667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-o-transform:translate(0px, 0px) scale(1.2074) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-o-transform:translate(0px, 0px) scale(1.2407) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-o-transform:translate(0px, 0px) scale(1.2667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-o-transform:translate(0px, 0px) scale(1.2852) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-o-transform:translate(0px, 0px) scale(1.2963) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-o-transform:translate(0px, 0px) scale(0.8617) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-o-transform:translate(0px, 0px) scale(0.8983) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-o-transform:translate(0px, 0px) scale(0.9733) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-o-transform:translate(0px, 0px) scale(1.0157) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-o-transform:translate(0px, 0px) scale(1.0209) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-o-transform:translate(0px, 0px) scale(1.0099) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-o-transform:translate(0px, 0px) scale(0.9966) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-o-transform:translate(0px, 0px) scale(0.9975) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-o-transform:translate(0px, 0px) scale(0.9993) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-o-transform:translate(0px, 0px) scale(1.0004) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-o-transform:translate(0px, 0px) scale(1.0005) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-o-transform:translate(0px, 0px) scale(1.0002) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-o-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-o-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-webkit-keyframes poke {
  0% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {-webkit-transform:translate(0px, 0px) scale(1.063) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {-webkit-transform:translate(0px, 0px) scale(1.1185) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {-webkit-transform:translate(0px, 0px) scale(1.1667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {-webkit-transform:translate(0px, 0px) scale(1.2074) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {-webkit-transform:translate(0px, 0px) scale(1.2407) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {-webkit-transform:translate(0px, 0px) scale(1.2667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {-webkit-transform:translate(0px, 0px) scale(1.2852) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {-webkit-transform:translate(0px, 0px) scale(1.2963) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-webkit-transform:translate(0px, 0px) scale(0.8617) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {-webkit-transform:translate(0px, 0px) scale(0.8983) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {-webkit-transform:translate(0px, 0px) scale(0.9733) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {-webkit-transform:translate(0px, 0px) scale(1.0157) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {-webkit-transform:translate(0px, 0px) scale(1.0209) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {-webkit-transform:translate(0px, 0px) scale(1.0099) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {-webkit-transform:translate(0px, 0px) scale(0.9966) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {-webkit-transform:translate(0px, 0px) scale(0.9975) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {-webkit-transform:translate(0px, 0px) scale(0.9993) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-webkit-transform:translate(0px, 0px) scale(1.0004) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {-webkit-transform:translate(0px, 0px) scale(1.0005) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {-webkit-transform:translate(0px, 0px) scale(1.0002) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {-webkit-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {-webkit-transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@keyframes poke {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  3.33% {transform:translate(0px, 0px) scale(1.063) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  6.67% {transform:translate(0px, 0px) scale(1.1185) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  10% {transform:translate(0px, 0px) scale(1.1667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  13.33% {transform:translate(0px, 0px) scale(1.2074) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  16.67% {transform:translate(0px, 0px) scale(1.2407) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  20% {transform:translate(0px, 0px) scale(1.2667) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.33% {transform:translate(0px, 0px) scale(1.2852) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  26.67% {transform:translate(0px, 0px) scale(1.2963) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  30% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {transform:translate(0px, 0px) scale(0.8617) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  36.67% {transform:translate(0px, 0px) scale(0.8983) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  40% {transform:translate(0px, 0px) scale(0.9733) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  43.33% {transform:translate(0px, 0px) scale(1.0157) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  46.67% {transform:translate(0px, 0px) scale(1.0209) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  50% {transform:translate(0px, 0px) scale(1.0099) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  53.33% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  56.67% {transform:translate(0px, 0px) scale(0.9966) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  60% {transform:translate(0px, 0px) scale(0.9975) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  63.33% {transform:translate(0px, 0px) scale(0.9993) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {transform:translate(0px, 0px) scale(1.0004) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  70% {transform:translate(0px, 0px) scale(1.0005) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  73.33% {transform:translate(0px, 0px) scale(1.0002) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80% {transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  83.33% {transform:translate(0px, 0px) scale(0.9999) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  86.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  93.33% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  96.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}


@-moz-keyframes pokeUp {
  0% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  4.76% {-moz-transform:translate(0px, 9.2212px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  9.52% {-moz-transform:translate(0px, 6.7809px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  14.29% {-moz-transform:translate(0px, 1.7797px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  19.05% {-moz-transform:translate(0px, -1.0495px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.81% {-moz-transform:translate(0px, -1.3907px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.57% {-moz-transform:translate(0px, -0.6577px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.1% {-moz-transform:translate(0px, 0.2287px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  42.86% {-moz-transform:translate(0px, 0.1682px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  47.62% {-moz-transform:translate(0px, 0.0441px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  52.38% {-moz-transform:translate(0px, -0.026px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  57.14% {-moz-transform:translate(0px, -0.0345px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.9% {-moz-transform:translate(0px, -0.0163px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.43% {-moz-transform:translate(0px, 0.0057px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.19% {-moz-transform:translate(0px, 0.0042px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80.95% {-moz-transform:translate(0px, 0.0011px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85.71% {-moz-transform:translate(0px, -0.0006px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90.48% {-moz-transform:translate(0px, -0.0009px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95.24% {-moz-transform:translate(0px, -0.0004px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-moz-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-ms-keyframes pokeUp {
  0% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  4.76% {-ms-transform:translate(0px, 9.2212px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  9.52% {-ms-transform:translate(0px, 6.7809px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  14.29% {-ms-transform:translate(0px, 1.7797px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  19.05% {-ms-transform:translate(0px, -1.0495px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.81% {-ms-transform:translate(0px, -1.3907px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.57% {-ms-transform:translate(0px, -0.6577px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.1% {-ms-transform:translate(0px, 0.2287px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  42.86% {-ms-transform:translate(0px, 0.1682px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  47.62% {-ms-transform:translate(0px, 0.0441px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  52.38% {-ms-transform:translate(0px, -0.026px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  57.14% {-ms-transform:translate(0px, -0.0345px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.9% {-ms-transform:translate(0px, -0.0163px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.43% {-ms-transform:translate(0px, 0.0057px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.19% {-ms-transform:translate(0px, 0.0042px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80.95% {-ms-transform:translate(0px, 0.0011px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85.71% {-ms-transform:translate(0px, -0.0006px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90.48% {-ms-transform:translate(0px, -0.0009px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95.24% {-ms-transform:translate(0px, -0.0004px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-ms-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-o-keyframes pokeUp {
  0% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  4.76% {-o-transform:translate(0px, 9.2212px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  9.52% {-o-transform:translate(0px, 6.7809px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  14.29% {-o-transform:translate(0px, 1.7797px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  19.05% {-o-transform:translate(0px, -1.0495px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.81% {-o-transform:translate(0px, -1.3907px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.57% {-o-transform:translate(0px, -0.6577px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.1% {-o-transform:translate(0px, 0.2287px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  42.86% {-o-transform:translate(0px, 0.1682px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  47.62% {-o-transform:translate(0px, 0.0441px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  52.38% {-o-transform:translate(0px, -0.026px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  57.14% {-o-transform:translate(0px, -0.0345px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.9% {-o-transform:translate(0px, -0.0163px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.43% {-o-transform:translate(0px, 0.0057px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.19% {-o-transform:translate(0px, 0.0042px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80.95% {-o-transform:translate(0px, 0.0011px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85.71% {-o-transform:translate(0px, -0.0006px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90.48% {-o-transform:translate(0px, -0.0009px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95.24% {-o-transform:translate(0px, -0.0004px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-o-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@-webkit-keyframes pokeUp {
  0% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  4.76% {-webkit-transform:translate(0px, 9.2212px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  9.52% {-webkit-transform:translate(0px, 6.7809px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  14.29% {-webkit-transform:translate(0px, 1.7797px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  19.05% {-webkit-transform:translate(0px, -1.0495px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.81% {-webkit-transform:translate(0px, -1.3907px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.57% {-webkit-transform:translate(0px, -0.6577px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.1% {-webkit-transform:translate(0px, 0.2287px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  42.86% {-webkit-transform:translate(0px, 0.1682px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  47.62% {-webkit-transform:translate(0px, 0.0441px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  52.38% {-webkit-transform:translate(0px, -0.026px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  57.14% {-webkit-transform:translate(0px, -0.0345px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.9% {-webkit-transform:translate(0px, -0.0163px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.43% {-webkit-transform:translate(0px, 0.0057px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.19% {-webkit-transform:translate(0px, 0.0042px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80.95% {-webkit-transform:translate(0px, 0.0011px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85.71% {-webkit-transform:translate(0px, -0.0006px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90.48% {-webkit-transform:translate(0px, -0.0009px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95.24% {-webkit-transform:translate(0px, -0.0004px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {-webkit-transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
@keyframes pokeUp {
  0% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  4.76% {transform:translate(0px, 9.2212px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  9.52% {transform:translate(0px, 6.7809px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  14.29% {transform:translate(0px, 1.7797px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  19.05% {transform:translate(0px, -1.0495px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  23.81% {transform:translate(0px, -1.3907px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  28.57% {transform:translate(0px, -0.6577px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  33.33% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  38.1% {transform:translate(0px, 0.2287px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  42.86% {transform:translate(0px, 0.1682px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  47.62% {transform:translate(0px, 0.0441px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  52.38% {transform:translate(0px, -0.026px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  57.14% {transform:translate(0px, -0.0345px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  61.9% {transform:translate(0px, -0.0163px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  66.67% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  71.43% {transform:translate(0px, 0.0057px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  76.19% {transform:translate(0px, 0.0042px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  80.95% {transform:translate(0px, 0.0011px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  85.71% {transform:translate(0px, -0.0006px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  90.48% {transform:translate(0px, -0.0009px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  95.24% {transform:translate(0px, -0.0004px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
  100% {transform:translate(0px, 0px) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);}
}
